import React, { useEffect } from "react";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import { FormGroup, Label, Form, Button, Alert, Input } from "reactstrap";
import moment from "moment";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import CheckInService from "../services/CheckInService";

export default function HomePage({
  translate,
  account,
  totalPersonMinistry,
  personMinistry,
}) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  useEffect(() => {
  }, [
    account.church.church_id,
    account.church.name,
  ]);

  function submitForm(e) {
    e.preventDefault();

    CheckInService({
      person_id: account.mygms_id,
      nij: account.nij,
      name: account.name,
      churchName: account.church.name,
      successTitle: translate("check_in_success"),
      successText: translate("thank_you_for_checking_in"),
      successButton: translate("ok"),
      duplicateTitle: translate("you_already_check_in"),
      duplicateText: translate(
        "you_cannot_check_in_on_the_same_day"
      ),
      duplicateButton: translate("ok"),
      errorButton: translate("please_try_again"),
      errorFooter: translate("send_error_to_developer"),
    });
  }

  return (
    <>
      <Alert color="dark">
        <span className="text-capitalize">{translate("welcome_home")},</span>
        <span className="text-capitalize ms-1">
          {account.name.toLowerCase()}
        </span>
      </Alert>

      <Form onSubmit={submitForm}>
        <FormGroup>
          <Label for="name" className="fw-bold">
            {translate("name")}
          </Label>
          <Input id="name" defaultValue={account.name} plaintext readOnly />
        </FormGroup>

        <FormGroup>
          <Label for="nij" className="fw-bold">
            {translate("nij")}
          </Label>
          <Input id="nij" defaultValue={account.nij} plaintext readOnly />
        </FormGroup>

        <FormGroup>
          <Label for="date" className="fw-bold">
            {translate("day")} & {translate("date")}
          </Label>
          <Input
            id="date"
            defaultValue={moment().format("dddd, DD MMMM YYYY")}
            plaintext
            readOnly
          />
        </FormGroup>

        <Button type="submit" color="dark" className="w-100 mt-2">
          {translate("check_in")} {translate("now")}
        </Button>
      </Form>
    </>
  );
}
