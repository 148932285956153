import axios from "axios";
import {
  api_url,
  header_content_type,
  header_accept,
  header_key,
} from "../utils/ConstantsUtil";

export default async function CheckInApi({
  person_id,
  nij,
  name,
}) {
  const data = {
    person_id: person_id,
    nij: nij,
    name: name,
  };

  return await axios.post(api_url + "/absent-training", data, {
    headers: {
      "Content-Type": header_content_type,
      Accept: header_accept,
      key: header_key,
    },
  });
}
