import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { FaHome, FaCalendarCheck } from "react-icons/fa";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import GetAbsentsService from "../services/GetAbsentsService";

function nameChurchService(absent) {
  let name = null;

  if (absent.church_service) {
    name = absent.church_service.name;
  }

  if (absent.church_service_id === "100000000") {
    name = "Special Service";
  }

  return name;
}

function timeChurchService(absent) {
  let time = null;

  if (absent.church_service) {
    time = absent.church_service.time;
    time = " - (" + time.substr(0, time.length - 3) + ") WIB";
  }

  if (absent.church_service_id === "100000000") {
    time = null;
  }

  return time;
}

export default function AbsentPage({
  translate,
  account,
  totalPersonMinistry,
}) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const titlePage = translate("absent");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaCalendarCheck },
  ];

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  function onChangeDate(value) {
    setDate(value.target.value);
  }

  const absents = GetAbsentsService({
    person_id: account.mygms_id,
    ministry_id: null,
    division_id: null,
    date: date,
    deleted_at: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  return (
    <>
      <h2>
        <FaCalendarCheck className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <Card className="mb-4">
        <CardBody>
          <CardTitle tag="h5">
            {translate("search")} {translate("absent")}{" "}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {translate("total_data")} : {absents.length}
          </CardSubtitle>

          <FormGroup>
            <Label for="date">{translate("date")}</Label>
            <Input
              type="date"
              id="date"
              name="date"
              onChange={onChangeDate}
              defaultValue={moment().format("YYYY-MM-DD")}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {absents.map((absent, key) => (
        <div key={absent.id}>
          <Table responsive striped bordered>
            <thead color="dark">
              <tr className="bg-dark text-white">
                <th>
                  <span>{key + 1}. </span>
                  <span>{moment(absent.created_at).format("D MMMM YYYY")}</span>
                  <span> - </span>
                  <span>{moment(absent.created_at).format("HH:mm")} WIB</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>
                    {nameChurchService(absent)} {timeChurchService(absent)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  {absent.ministry.name}, {absent.division.name}
                </td>
              </tr>
              <tr>
                <td>{absent.church.name}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ))}
    </>
  );
}
