import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function CheckStatusVolunteerService({ totalPersonMinistry }) {
  const navigate = useNavigate();

  useEffect(() => {
    // if (totalPersonMinistry === 0) {
    //   if (window.location.pathname !== "/non-active") {
    //     return navigate("/non-active");
    //   }
    // } else {
    //   if (window.location.pathname === "/non-active") {
    //     return navigate("/");
    //   }
    // }
  }, [totalPersonMinistry, navigate]);
}
